<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title="false">
    <a-spin :spinning="loading">
      <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
        <a-form :form="form">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="区域"
            :required="true"
            :validateStatus="checkParams.areaCodeStatus"
            :help="checkParams.areaCodeStatusMsg"
          >
            <a-cascader
              style="width: 300px;"
              :options="regionList"
              placeholder="省-市-区(县)"
              :field-names="{label: 'label', value: 'value', children: 'children' }"
              :value="addDefaultRegion"
              @change="changeAddRegion"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="小区"
            :required="true"
            :validateStatus="checkParams.communityIdStatus"
            :help="checkParams.communityIdStatusMsg"
          >
            <a-select
              style="width:300px"
              placeholder="请选择小区"
              v-model="queryParam.communityId"
              @change="changeCommunityId"
            >
              <a-select-option :key="0" :value="0">请选择小区</a-select-option>
              <a-select-option v-for="item in regionCommunityAdd" :key="item.keyId" :value="item.id">
                {{ item.communityName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="绑定住户"
          >
            <a-select
              style="width: 300px;"
              :value="queryParam.residentId"
              allow-clear
              show-search
              placeholder="请输入姓名/手机号关键字、ID"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="fetching ? undefined : null"
              :options="searchMember"
              @search="handleMemberSearch"
              @change="handleMemberChange"
            >
              <template v-if="fetching" #notFoundContent>
                <div style="text-align: center" v-if="isMemberNull === false">
                  <a-spin size="small" />
                </div>
                <div v-else>
                  <div style="text-align: center">{{ notFoundContent }}</div>
                </div>
              </template>
            </a-select>
            <span style="margin-left: 20px; color: #ff2e33">*请先选择区域，小区，再搜索住户</span>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="申请人姓名"
            :validateStatus="checkParams.titleStatus"
            :help="checkParams.titleStatusMsg"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入申请人姓名"
              v-decorator="['applicantName', {initialValue: queryParam.applicantName, rules: [{required: true, validator: titleCheck, validateTrigger: 'change'}]}]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="出生日期"
            :required="true"
            :validateStatus="checkParams.birthdayStatus"
            :help="checkParams.birthdayStatusMsg"
          >
            <a-date-picker
              placeholder="请输入申请人出生日期"
              v-model="queryParam.applicantBirthday"
              style="width: 300px;"
              valueFormat="YYYY-MM-DD"
              @change="birthdayCheck"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="申请人地址"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入申请人地址"
              v-model="queryParam.applicantAddress"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="联系人姓名"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入联系人姓名"
              v-model="queryParam.contactName"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="联系人电话"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入联系人电话"
              v-model="queryParam.contactPhone"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="备注"
          >
            <a-textarea
              style="width: 60%;"
              :rows="3"
              v-model="queryParam.description"
              placeholder="备注"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="特殊情况类型"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入"
              v-model="queryParam.field1"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="身份证号"
          >
            <a-input
              style="width: 300px;"
              placeholder="请输入"
              v-model="queryParam.field2"
            />
          </a-form-item>
          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="padding-left: 350px"
          >
            <a-button
              type="primary"
              @click="saveApi"
              :disabled="saveDisabled"
              :loading="saveLoading"
            >
              提交
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </page-header-wrapper>
</template>

<script>
import { formInfo, saveForm } from '@/api/application'
import { regionList } from '@/api/region'
import { regionCommunity } from '@/api/community'
import { searchResident } from '@/api/resident'
export default {
  name: 'AddSpecial',
  components: {
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'addNoticeFrom' }),
      loading: false,
      saveDisabled: false,
      saveLoading: false,
      labelCol: {
        lg: { span: 2 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 17 },
        sm: { span: 17 }
      },
      checkParams: {
        titleStatus: 'success',
        titleStatusMsg: null,
        areaCodeStatus: 'success',
        areaCodeStatusMsg: null,
        communityIdStatus: 'success',
        communityIdStatusMsg: null,
        sendTypeStatus: 'success',
        sendTypeStatusMsg: null,
        birthdayStatus: 'success',
        birthdayStatusMsg: null
      },
      queryParam: {
        id: '',
        keyId: 0,
        communityId: '',
        areaCode: 0,
        residentId: undefined,
        residentKeyId: 0,
        applicantName: '',
        applicantAddress: '',
        applicantBirthday: '',
        applicationType: 'SpecialApplicationForm',
        contactName: '',
        contactPhone: '',
        description: '',
        status: 0,
        field1: '',
        field2: '',
        field3: '',
        field4: '',
        field5: '',
        field6: '',
        field7: '',
        field8: '',
        field9: '',
        field10: '',
        longField1: '',
        longField2: '',
        longField3: '',
        provinceId: 0,
        cityId: 0,
        region: [0, 0, 0]
      },
      regionList: [],
      regionCommunity: [],
      regionCommunityAdd: [],
      addDefaultRegion: [],
      searchMember: [],
      searchTitle: '',
      timeout: null,
      fetching: false,
      isMemberNull: false,
      notFoundContent: '未搜到住户',
      userData: []
    }
  },
  created () {
    this.regionListApi()
    if (this.$route.query.formId !== 0 && this.$route.query.formId !== undefined && this.$route.query.formId !== '') {
      this.queryParam.keyId = parseInt(this.$route.query.formId)
    }

    if (this.queryParam.keyId > 0) {
      this.articleInfoApi()
    }
  },
  methods: {
    titleCheck () {
      const _this = this
      if (_this.queryParam.applicantName.trim() !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入申请人姓名'
      }
    },
    saveApi () {
      console.log('saveApi')
      const _this = this
      if (this.queryParam.areaCode === '' || this.queryParam.areaCode === 0) {
        this.checkParams.areaCodeStatus = 'error'
        this.checkParams.areaCodeStatusMsg = '请选择区域'
        return false
      } else {
        this.checkParams.areaCodeStatus = 'success'
        this.checkParams.areaCodeStatusMsg = null
      }
      if (this.queryParam.communityId === '' || this.queryParam.communityId === 0) {
        this.checkParams.communityIdStatus = 'error'
        this.checkParams.communityIdStatusMsg = '请选择小区'
        return false
      } else {
        this.checkParams.communityIdStatus = 'success'
        this.checkParams.communityIdStatusMsg = null
      }
      if (_this.queryParam.applicantName.trim() !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入申请人姓名'
        return false
      }

      if (_this.queryParam.applicantBirthday !== '' && _this.queryParam.applicantBirthday !== null) {
        _this.checkParams.birthdayStatus = 'success'
        _this.checkParams.birthdayStatusMsg = null
      } else {
        _this.checkParams.birthdayStatus = 'error'
        _this.checkParams.birthdayStatusMsg = '请输入申请人出生日期'
        return false
      }
      saveForm(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          this.$router.push({ path: '/application/special-list' })
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    articleInfoApi () {
      const _this = this
      _this.loading = true
      formInfo({ keyId: _this.queryParam.keyId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.queryParam = res.result
          _this.addDefaultRegion = _this.queryParam.region
          _this.regionCommunityApi(_this.queryParam.areaCode, 1)
          if (_this.queryParam.residentKeyId > 0) {
            _this.searchTitle = _this.queryParam.residentKeyId
            _this.searchAllMember(1)
          } else {
            _this.searchTitle = ''
            _this.queryParam.residentId = undefined
          }
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.loading = false
      }).catch((err) => {
        console.log(err)
        _this.loading = false
      })
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', _this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionCommunityApi (areaCode, type) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionCommunity = res.result.data
          } else {
            _this.regionCommunityAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeAddRegion (e) {
      this.addDefaultRegion = e
      this.queryParam.provinceId = e[0] ? e[0] : 0
      this.queryParam.cityId = e[1] ? e[1] : 0
      this.queryParam.areaCode = e[2] ? e[2] : 0
      if (this.queryParam.areaCode === '' || this.queryParam.areaCode === 0) {
        this.checkParams.areaCodeStatus = 'error'
        this.checkParams.areaCodeStatusMsg = '请选择区域'
      } else {
        this.checkParams.areaCodeStatus = 'success'
        this.checkParams.areaCodeStatusMsg = null
      }

      this.regionCommunityApi(this.queryParam.areaCode, 1)
      this.queryParam.communityId = 0
    },
    changeCommunityId () {
      if (this.queryParam.communityId === '' || this.queryParam.communityId === 0) {
        this.checkParams.communityIdStatus = 'error'
        this.checkParams.communityIdStatusMsg = '请选择小区'
      } else {
        this.checkParams.communityIdStatus = 'success'
        this.checkParams.communityIdStatusMsg = null
      }
    },
    handleMemberSearch (e) {
      const _this = this
      _this.searchTitle = e.trim()
      if (_this.timeout) {
        clearTimeout(_this.timeout)
        _this.timeout = null
      }
      _this.timeout = setTimeout(function () {
        _this.searchAllMember()
      }, 500)
    },
    searchAllMember (isId = 0) {
      const _this = this
      if (_this.searchTitle === '') {
        _this.searchMember = []
        _this.userData = []
        _this.fetching = false
        _this.isMemberNull = false
        return false
      }

      _this.fetching = true
      _this.isMemberNull = false
      var searchParams = {
        title: _this.searchTitle,
        areaCode: 0,
        communityId: 0
      }
      if (isId === 0) {
        searchParams.areaCode = _this.queryParam.areaCode
        searchParams.communityId = _this.queryParam.communityId
      }
      searchResident(searchParams)
        .then((res) => {
          console.log('searchCourseApi', res)
          _this.searchMember = res.result.selectList
          _this.userData = res.result.userData
          if (_this.searchMember.length > 0) {
            _this.fetching = false
            _this.isMemberNull = false
          } else {
            _this.fetching = true
            _this.isMemberNull = true
          }
        })
        .catch((err) => {
          console.log(err)
          _this.fetching = false
          _this.isMemberNull = false
        })
    },
    handleMemberChange (e) {
      console.log('handleMemberChange', e)
      if (e === '' || e === undefined) {
        this.queryParam.residentId = undefined
        this.searchMember = []
      } else {
        this.queryParam.residentId = e
        if (this.userData[this.queryParam.residentId]) {
          console.log(this.userData[this.queryParam.residentId])
          var userDataInfo = this.userData[this.queryParam.residentId]
          if (this.queryParam.keyId <= 0) {
            this.queryParam.applicantName = userDataInfo.realName
            this.titleCheck()
          } else {
            if (this.queryParam.applicantName === '') {
              this.queryParam.applicantName = userDataInfo.realName
              this.titleCheck()
            }
          }
        }
      }
    },
    birthdayCheck () {
      if (this.queryParam.applicantBirthday !== '' && this.queryParam.applicantBirthday !== null) {
        this.checkParams.birthdayStatus = 'success'
        this.checkParams.birthdayStatusMsg = null
      } else {
        this.checkParams.birthdayStatus = 'error'
        this.checkParams.birthdayStatusMsg = '请输入申请人出生日期'
      }
    }
  }
}
</script>
